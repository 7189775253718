import * as React from "react"
import Layout from '../components/layout/Layout';

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const PrivacyPage = ({ }) => {
  return (
    <Layout>


      <main style={pageStyles}>
        <title>Privacy Policy</title>

        <h1 style={headingStyles}>Privacy Policy</h1>

        <div>
          <p className="mb-2 mt-4"><strong>Privacy Policy</strong></p>
          <p>GitGlow built the GitGlow app as a Commercial app. This SERVICE is provided by GitGlow and is intended for use as is.</p>
          <p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.</p>
          <p>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
          <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at GitGlow unless otherwise defined in this Privacy Policy.</p>
          <p className="mb-2 mt-4"><strong>Information Collection and Use</strong></p>
          <p>For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to email. The information that we request will be retained by us and used as described in this privacy policy.</p>
          <p>The app does use third party services that may collect information used to identify you.</p>
          <p>Link to privacy policy of third party service providers used by the app</p>
          <ul>
            <li><a href="https://www.google.com/policies/privacy/">Google Play Services</a></li>
          </ul>
          <p className="mb-2 mt-4"><strong>Security</strong></p>
          <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
          <p className="mb-2 mt-4"><strong>Changes to This Privacy Policy</strong></p>
          <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
          <p>This policy is effective as of 2021-05-10</p>
          <p className="mb-2 mt-4"><strong>Contact Us</strong></p>
          <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at contact@gitglow.com.</p>

        </div>
      </main>

    </Layout>
  )
}

export default PrivacyPage
